import {cn} from "../../lib/utils";
import Breadcrumbs from "../common/breadcrumbs";
import {Popover, PopoverContent, PopoverTrigger} from "../ui/popover";
import type {Deployment} from "memecoin-models-specification-ts/user/Deployment";
import type {User} from "memecoin-models-specification-ts/user/User";
import { ScreenMode } from "./side-bar";


export interface TopBarProps {
    userToken: any;
    user: User;
    deployment: Deployment;
    instance?: any;
    userPrefsCookie?: any;
}

export function TopBar ({userToken, user, deployment, instance, userPrefsCookie, className, ...props}: React.HTMLAttributes<HTMLElement> & TopBarProps) {
    if(userPrefsCookie?.screenMode === ScreenMode.Full) {
        return (
            <>
                <Breadcrumbs className={"absolute ml-4 mt-1"} instance={instance} />
                <div className="mt-6"></div>
            </>
        );
    }
    return (
        <div className={cn("w-full h-16 flex text-gray-600", className)}>

            <Breadcrumbs instance={instance} />

            <div className="ml-auto"></div>
            {deployment &&
                <>
                    <i className="mr-2 fi fi-rr-globe my-2.5" />
                    <div className="mx-2 my-2">
                        {deployment.name}
                    </div>
                </>
            }

            <Popover>
                <PopoverTrigger asChild>
                    <div className="flex cursor-pointer">
                        <i className="ml-10 mr-2 fi fi-rr-user my-2.5" />
                        <div className="ml-2 mr-6 my-2">
                            {userToken.given_name}
                        </div>
                    </div>
                </PopoverTrigger>
                <PopoverContent className="w-[250px] min-w-[250px] p-0">
                    <div className="py-2 px-4 flex text-gray-400">
                        <i className="mt-[2px] mr-4 fi fi-rr-user" />
                        <div>
                            {`${user.firstName} ${user.lastName}`}
                        </div>
                    </div>
                    <div className="py-2 px-4 flex text-gray-400">
                        <i className="mt-[2px] mr-4 fi fi-rr-globe" />
                        <div>
                            {`${user.associatedOrganizationName}`}
                        </div>
                    </div>
                    <>
                        <div className="py-2 px-4 flex hover:bg-gray-300">
                            <i className="mt-[2px] mr-4 fi fi-rr-exit" />
                            <div>
                                Logout
                            </div>
                        </div>
                    </>
                </PopoverContent>
            </Popover>
        </div>
    );
}
