
import axios, {CancelTokenSource} from "axios";
import {BadRequestError, ConflictError, InternalServerError, NotFoundError, ServiceUnavailableError} from "../errors/Errors";
import UserService from "./UserService";
import {Headers} from "../constants/types";
import { InstanceListFilter } from "../model/InstanceListFilter";

export class DataService {
    private baseURL = "";
    private apiPrefix = "/api/v1";
    private axiosOptions: any;
    private axios;
    private service;
    private deploymentId: string|undefined;
    private source: CancelTokenSource;

    constructor(service: string, axiosOptions?: {}) {
        const CancelToken = axios.CancelToken;
        this.source = CancelToken.source();
        this.service = service;
        this.axiosOptions = axiosOptions;
        this.axios = axios.create({
            cancelToken: this.source.token,
            validateStatus: (status: number) => {
                return status >= 200 && status <= 503;
            },
        });
        const params = new URLSearchParams(location.search);
        this.axios.interceptors.request.use(async (config) => {
            if (UserService.isLoggedIn()) {
                const cb = () => {
                    if(!config.headers) {
                        config.headers = {} as any;
                    }
                    if(this.deploymentId) {
                        //console.log("Using x-deployment-id =", this._deployment.id);
                        config.headers['x-deployment-id'] = this.deploymentId;
                    }
                    else {
                        //console.log("x-deployment-id not set");
                    }
                    if(params && params.get('internal')) {
                        config.headers['x-transport'] = 'internal';
                    }
                    config.headers.Authorization = `Bearer ${UserService.getToken()}`;
                    return Promise.resolve(config);
                };
                await UserService.updateToken(cb);
            }
            return config;
        });
    }

    getAxiosSource() {
        return this.source;
    }

    getDeploymentId() {
        return this.deploymentId;
    }

    getUrl(endpoint: string, id?: any, childEndpoint?: any) {
        if (childEndpoint) {
            return `${this.baseURL}/${this.service}${this.apiPrefix}/${endpoint}/${id}/${childEndpoint}`;
        }
        else if (id) {
            return `${this.baseURL}/${this.service}${this.apiPrefix}/${endpoint}/${id}`;
        }
        return `${this.baseURL}/${this.service}${this.apiPrefix}/${endpoint}`;
    }

    logRequest(method: string, url: string, data?: {}) {
        if (data) {
            console.debug('Request ' + method + ' ' + url + '\n' + JSON.stringify(data, null, 4));
        }
        else {
            console.debug('Request ' + method + ' ' + url);
        }
    }

    logResponse(method: string, url: string, data: {}) {
        console.debug('Response ' + method + ' ' + url + '\n' + JSON.stringify(data, null, 4));
    }

    deployment(deploymentId: string|null|undefined) {
        this.deploymentId = deploymentId ? deploymentId : undefined;
        return this;
    }

    handleResponse(response: any) {
        const status = response.status;
        const data = response.data;

        if (status >= 200 && status < 300) {
            // return data as same as before
            return response.data;
        }
        else {
            if (data instanceof Object) {
                data.status = status;
            }
            if (status === 400) {
                throw new BadRequestError(data);
            }
            else if (status === 404) {
                throw new NotFoundError(data);
            }
            else if (status === 409) {
                throw new ConflictError(data);
            }
            else if (status === 503) {
                throw new ServiceUnavailableError(data);
            }
            else {
                throw new InternalServerError(data);
            }
        }
    }

    /**
     * get : base endpoint
     * @param endpoint
     */
    get(endpoint: string, id?: string, filter?: InstanceListFilter, pagination?: string, useFilterAnd?: boolean, childEndpoint?: string) {
        const url = this.getUrl(endpoint, id, childEndpoint);
        const method = 'GET';
        this.logRequest(method, url);

        let options = this.axiosOptions || {};
        options = {
            ...this.axiosOptions
        }
        options.headers = {
            ...options.headers,
        }
        if(filter && filter.db) {
            options.headers[Headers.HTTP_HEADER_X_FILTER] = filter.db;
            options.headers[Headers.HTTP_HEADER_X_FILTER_USE_OR] = useFilterAnd ? 'false' : 'true';
        }
        if(pagination) {
            options.headers[Headers.HTTP_HEADER_X_PAGINATION] = pagination;
        }
        return this.axios.get(url, options)
            .then(res => {
                this.logResponse(method, url, res.data);
                this.handleResponse(res);
                return res;
            })
    }

    /**
     * put : base endpoint
     * @param endpoint
     * @param data
     */
    put(endpoint: string, data: {[index:string]: any}) {
        const url = this.getUrl(endpoint, data.id);
        const method = 'PUT';
        this.logRequest(method, url, data);
        return this.axios.put(url, data, this.axiosOptions)
            .then(res => {
                this.logResponse(method, url, res.data);
                this.handleResponse(res);
                return res.data;
            })
    }

    /**
     * post : base endpoint
     * @param endpoint
     * @param data
     */
    post(endpoint: string, data: {[index:string]: any}) {
        const url = this.getUrl(endpoint);
        const method = 'POST';
        this.logRequest(method, url, data);
        return this.axios.post(url, data, this.axiosOptions)
            .then(res => {
                this.logResponse(method, url, res.data);
                this.handleResponse(res);
                return res.data;
            })
    }

    /**
     * delete : base endpoint
     * @param endpoint
     * @param data
     */
    delete(endpoint: string, id: string) {
        const url = this.getUrl(endpoint, id);
        const method = 'DELETE';
        this.logRequest(method, url);
        return this.axios.delete(url, this.axiosOptions)
            .then(res => {
                this.logResponse(method, url, res.data);
                this.handleResponse(res);
                return res.data;
            })
    }

    /**
     * request : base endpoint
     * @param endpoint
     * @param data
     */
    request(method: string, endpoint: string, id?: string, data?: {[index:string]: any}) {
        const url = this.getUrl(endpoint, id);
        this.logRequest(method, url, data);
        const options = Object.assign({}, this.axiosOptions);
        options.method = method;
        options.url = url;
        options.data = data;
        return this.axios.request(options)
            .then(res => {
                this.logResponse(method, url, res.data);
                this.handleResponse(res);
                return res.data;
            })
    }

}
