import {Cross2Icon} from "@radix-ui/react-icons"

import {Button} from "../ui/button"
import {Input} from "../ui/input"
import {isEmpty} from "../../utils/util";
import {cn} from "../../lib/utils";
import { Link } from "react-router-dom";

interface DataTableToolbarProps {
    className?: string;
    filterValue: string;
    onFilterChange: (value: string) => void;
    onReset: () => void;
    hideAddNew?: boolean;
    addNewLabel?: string;
    addNewRoute: string;
}

export function FilterAddNewToolbar({
    className,
    addNewLabel,
    filterValue, 
    hideAddNew,
    onFilterChange,
    onReset,
    addNewRoute,
}: DataTableToolbarProps) {
    return (
        <div className={cn("flex items-center justify-between", className)}>
            <div className="flex flex-1 items-center space-x-2">
                <Input
                    placeholder="Filter..."
                    value={filterValue}
                    onChange={(event) => onFilterChange(event.target.value)}
                    className="pl-8 h-8 w-[30vw]"
                    
                />
                <i className="mt-1 fi fi-rr-search absolute text-gray-500" />
                {!isEmpty(filterValue) && (
                    <Button
                        variant="ghost"
                        onClick={onReset}
                        className="h-8 px-2 lg:px-3"
                    >
                        Reset
                        <Cross2Icon className="ml-2 h-4 w-4"/>
                    </Button>
                )}
            </div>
            {!hideAddNew &&
                <Link to={addNewRoute}>
                    <Button type="submit" className="float-right text-white ml-4 my-2 bg-blue-900 hover:bg-primary active:bg-blue-400">
                        {addNewLabel ? addNewLabel : "+ Add new"}
                    </Button>
                </Link>
            }
        </div>
    )
}