import {Deployment} from "memecoin-models-specification-ts/user/Deployment";
import type {User as RaasifyUser} from "memecoin-models-specification-ts/user/User";
import Header from "../components/common/header";
import * as React from "react";
import {Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle} from "../components/ui/card";
import {formatDateNoSecs, getById, isEmpty} from "../utils/util";
import {FilterAddNewToolbar} from "../components/common/filter-addnew-toolbar";
import {ReactElement, useEffect, useRef, useState} from "react";
import {Separator} from "../components/ui/separator";
import {cn} from "../lib/utils";
import {AuthenticatedLink} from "../components/common/AuthticatedLink";
import {Popover, PopoverContent, PopoverTrigger} from "../components/ui/popover";
import {CommonForm, CommonFormField, CommonFormInputType, ConfirmAction} from "../components/form/common-form";
import {Button} from "../components/ui/button";
import {zodResolver} from "@hookform/resolvers/zod";
import {z} from "zod";
import {Sheet, SheetContent, SheetHeader, SheetTitle} from "../components/ui/sheet";
import { ScreenMode } from "../components/application/side-bar";
import { Headers, GraphicsId } from "common-models-ts";
import { Link, useSubmit } from "react-router-dom";
import { useForm } from "react-hook-form";

import { Services, USER_LOGIC } from "../services/Services";

export const TAGLINE = "Deployments are application instances with their own set of data.";

interface DeploymentCardProps {
    deployment: Deployment;
    isSelected: boolean;
}

function DeploymentCard({deployment, isSelected}: DeploymentCardProps) {
    const deploymentLink = deployment.invitee ? `./?selected=${deployment.id}` : `/deployments/${deployment.id}`;
    return (
        <div className="m-[0.3rem] relative">
            {deployment.invitee && <div className="text-[0.8rem] z-50 absolute top-10 left-[5rem] text-gray-100 font-semibold bg-blue-500 px-2">
                Invited
            </div>}
            <Link to={`./?selected=${deployment.id}`} className="absolute right-[5px] top-[1px] p-4 cursor-pointer z-10">
                <i className="fi fi-rr-settings-sliders text-2xl"/>
            </Link>
            <Link to={deploymentLink}>
                <Card className={cn("w-[285px] h-[250px] relative shadow-md", isSelected ? "border-blue-600 shadow-[0_8px_10px_-1px_rgba(0,0,200,0.3)]" : "")}>
                    <CardHeader>
                        <CardTitle>
                            <i className="fi fi-rs-layers text-5xl text-[color:#555599]"></i>
                            <div className="mt-2 font-semibold text-xl">{deployment.name}</div>
                        </CardTitle>
                        <CardDescription className="">
                            {deployment.description}
                        </CardDescription>
                    </CardHeader>
                    <CardContent>

                    </CardContent>
                    <CardFooter className="inline-block text-xs">
                        <div className="bottom-[15px] right-[20px] absolute">
                            <div className="flex float-right mb-1">
                                <div className="mr-1">Creation date:</div>
                                <div>{formatDateNoSecs(deployment.created as string)}</div>
                            </div>
                            <div className="flex float-right">
                                <div className="mr-1">Created by:</div>
                                <div>{deployment.createdBy}</div>
                            </div>
                        </div>
                    </CardFooter>
                </Card>
            </Link>
        </div>
    )
}

interface AssetRowProps {
    name: string;
    description: string;
    url?: string;
    className?: string;
    headerRow?: boolean;
    token?: string;
}


function AssetRow({name, description, url, className, headerRow, token}: AssetRowProps) {

    const row = (
        <div className="inline-block">
            <div className={cn("flex my-2 p-2 text-sm", className)}>
                <div className="w-[100px]">
                    {name}
                </div>
                <div className="w-[40px] text-center">
                    {!headerRow ?
                        <i className="fi fi-rr-down-to-line"/>
                        : <div>{' '}</div>
                    }
                </div>
                <div className="w-[200px] ml-4">
                    {description}
                </div>
            </div>
            {!headerRow && <Separator/>}
        </div>
    )

    if (url && token) {
        return (
            <AuthenticatedLink url={url} token={token}>
                {row}
            </AuthenticatedLink>
        )
    }
    else {
        return row;
    }
}

enum DrawerMode {
    Information = "Information",
    Edit = "Edit",
    Share = "Share",
}

interface DeploymentMenuProps {
    deployment: Deployment;
    onModeChange: (mode: DrawerMode) => void;
    mode: DrawerMode;
}

function DeploymentMenu({deployment, onModeChange, mode}: DeploymentMenuProps) {
    const [open, setOpen] = useState(false);
    
    const handleClick = (mode: DrawerMode) => {
        onModeChange(mode);
        setOpen(false);
    }
    
    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <div className="cursor-pointer p-2">
                    <i className="fi fi-br-menu-dots-vertical"/>
                </div>
            </PopoverTrigger>
            <PopoverContent className="p-0 min-w-[160px]">
                <div>
                    {mode !== DrawerMode.Information &&
                        <div className="py-2 px-4 flex hover:bg-gray-300" onClick={() => handleClick(DrawerMode.Information)}>
                            <i className="mt-[2px] mr-4 fi fi-rr-info"/>
                            <div>
                                Information
                            </div>
                        </div>
                    }
                    {mode !== DrawerMode.Edit && deployment.role === 'Admin' &&
                        <div className="py-2 px-4 flex hover:bg-gray-300" onClick={() => handleClick(DrawerMode.Edit)}>
                            <i className="mt-[2px] mr-4 fi fi-rr-edit"/>
                            <div>
                                Edit
                            </div>
                        </div>
                    }
                    {mode !== DrawerMode.Share &&
                        <div className="py-2 px-4 flex hover:bg-gray-300" onClick={() => handleClick(DrawerMode.Share)}>
                            <i className="mt-[2px] mr-4 fi fi-rr-share"/>
                            <div>
                                Share
                            </div>
                        </div>
                    }
                </div>
            </PopoverContent>
        </Popover>
    )
}

interface DeploymentInfoProps {
    deployment: Deployment;
    token?: string;
    onModeChange: (mode: DrawerMode) => void;
    mode: DrawerMode;
    members?: Array<RaasifyUser>;
    children?: ReactElement;
    showDescription?: boolean;
    cardClass?: string;
}

function DeploymentSheet({deployment, onModeChange, mode, showDescription, children, cardClass}: DeploymentInfoProps) {
    const link = useRef<HTMLAnchorElement | null>(null);
    const logo: any = deployment.logo;
    return (
        <Sheet open={deployment !== undefined}>
            <SheetContent 
                className={cn("", cardClass)} 
                //onClose={() => onModeChange(DrawerMode.Information)} 
                closeUrl={"/deployments"} 
                onOpenAutoFocus={(e) => { onModeChange(DrawerMode.Information); e.preventDefault(); }}
                onInteractOutside={(e) => (link.current as any).click()}
            >
                    <a ref={link} href={`.`}></a>
                    <SheetHeader>
                        <SheetTitle>{mode}</SheetTitle>
                    </SheetHeader>
                    <div className="my-4">
                        <div className="m-0 absolute top-2 right-10 text-[16px] flex">
                            {(deployment.role === 'Admin' || deployment.role === 'AppAdmin') && !deployment?.invitee &&
                                <DeploymentMenu
                                    deployment={deployment}
                                    onModeChange={onModeChange}
                                    mode={mode}
                                />
                            }
                        </div>
                        {showDescription &&
                            <div className="flex flex-col">
                                <div className="flex flex-col text-[color:#555599] -mt-4 w-full justify-center items-center">
                                    {logo 
                                        ? <img src={logo} alt="" width="400" className="mt-5" /> 
                                        : <i className="text-center fi fi-rs-layers text-[160px]"/>
                                    }
                                    <div className="text-center font-semibold text-xl mt-10 mb-7">{deployment.name}</div>
                                    <Separator className="mt-1 mb-4"/>
                                </div>
                                <div className="flex flex-col">
                                    <div className="mb-2 font-semibold text-xl">Description</div>
                                    <div className="">{deployment.description}</div>
                                    <Separator className="mt-8"/>
                                </div>
                            </div>
                        }
                        <div>
                            {children}
                        </div>
                    </div>
            </SheetContent>
        </Sheet>
    )
}

function DeploymentInfo({deployment, token, onModeChange, mode}: DeploymentInfoProps) {
    const form = useForm({
        defaultValues: deployment as any,
    });
    const {register, setValue} = form;
    
    return (
        <div className="flex flex-col">
            <div className="flex flex-col text-sm">
                <div className="mt-6 mb-2 font-semibold text-xl">Deployment Information</div>
                <div className="mt-2 font-semibold">
                    Deployment Id:
                </div>
                <div>
                    {deployment.id}
                </div>
                <div className="font-semibold mt-4">
                    Subdomain:
                </div>
                <div>
                    {deployment.subdomain}
                </div>
                <Separator className="mt-8"/>
            </div>
            {!deployment.invitee && (deployment?.role === 'Admin'  || deployment?.role === 'User') &&
                <div className="flex flex-col">
                    <div className="mt-6 mb-4 font-semibold text-xl">Assets</div>
                    <AssetRow
                        className="bg-gray-300 font-semibold mb-0"
                        name={"Name"}
                        description={"Description"}
                        headerRow
                    />
                    <AssetRow
                        name={"Edge ISO"}
                        description={"Edge activation information"}
                        url={`/user/api/v1/deployments/${deployment?.id}/iso`}
                        token={token}
                    />
                    <AssetRow
                        name={"Edge Zip"}
                        description={"Edge activation information"}
                        url={`/user/api/v1/deployments/${deployment?.id}/zip`}
                        token={token}
                    />
                    <AssetRow
                        name={"API PKI"}
                        description={"API authentication certificate"}
                        url={`/user/api/v1/deployments/${deployment?.id}/pki`}
                        token={token}
                    />
                    <AssetRow
                        name={"Edge Qcow2"}
                        description={"Edge Image"}
                        url={"/user/api/v1/images/kvm"}
                        token={token}
                    />
                </div>
            }
            {deployment.invitee &&
                <CommonForm
                    instance={deployment}
                    form={form}
                    hideButtons
                >
                    <div className="flex flex-col">
                        <div className="mt-6 mb-2 font-semibold text-xl">Accept or Decline Invitation</div>
                        <div className="mt-6 mb-8 flex w-full">
                            <Button
                                className="w-[175px] text-center bg-red-700"
                                {...register('action')}
                                onClick={() => setValue('action', 'decline')}
                            >
                                Decline
                            </Button>
                            <Button
                                className="w-[175px] text-center bg-green-700 ml-4"
                                {...register('action')}
                                onClick={() => setValue('action', 'accept')}
                            >
                                Accept
                            </Button>
                        </div>
                    </div>
                </CommonForm>
            }
        </div>
    )
}

function DeploymentEdit({deployment, onModeChange, mode}: DeploymentInfoProps) {
    const submit = useSubmit();
    const [deleting, setDeleting] = useState(false);
    const [logo, setLogo] = useState<any>(deployment.logo);
    const form = useForm({
        defaultValues: deployment as any,
    });
    const {register, setValue, formState} = form;
    
    const onDeleteConfirm = () => {
        const data = {...deployment, action: "delete"} as any;
        submit(data, {
            action: "",
            method: "post",
            preventScrollReset: false,
            encType: "application/x-www-form-urlencoded",
            replace: false,
            relative: "route",
        });
    }

    return (
        <div className="flex flex-col">
            <CommonForm
                instance={deployment}
                form={form}
                onSaveClick={() => setValue('action', 'update')}
                buttonClass={"w-[220px]"}
            >
                <div className="flex flex-col text-[color:#555599] mt-1">
                    <div className="h-[160px] mb-10">
                        <CommonFormField
                            itemClass="w-full h-[260px] -ml-4 -mt-3.5"
                            valueClass="min-w-[350px] text-center text-xl border-none shadow-none "
                            name={"logo"}
                            label={"logo"}
                            description={"logo"}
                            inputType={CommonFormInputType.DropZone}
                            form={form}
                            onChange={setLogo}
                            hideLabel
                            hideSeparator
                        >
                            {logo ? <img src={logo} alt="" className="max-h-[160px]" /> : <i className="text-center fi fi-rs-layers text-[160px]"/>}
                        </CommonFormField>
                    </div>
                    <div className="text-center font-semibold mt-7">
                        <CommonFormField
                            itemClass="min-w-[350px] -ml-4 -mt-3.5"
                            valueClass="min-w-[350px] text-center text-xl border-none shadow-none "
                            name={"name"}
                            label={"name"}
                            description={"name"}
                            inputType={CommonFormInputType.Input}
                            form={form}
                            hideLabel
                            hideSeparator
                        />
                    </div>
                </div>
                <div className="-mt-2 mb-2 font-semibold text-xl">Description</div>
                <CommonFormField
                    itemClass="min-w-[350px] min-h-[80px] h-[80px] -ml-4"
                    valueClass="min-w-[350px] min-h-[80px] h-[80px]"
                    name={"description"}
                    label={"Description"}
                    description={"description"}
                    inputType={CommonFormInputType.Textarea}
                    form={form}
                    hideLabel
                />
                <div className="mb-2 font-semibold text-xl">Subdomain</div>
                <CommonFormField
                    itemClass="min-w-[350px] -ml-4"
                    valueClass="min-w-[350px]"
                    name={"subdomain"}
                    label={"Subdomain"}
                    description={"subdomain"}
                    inputType={CommonFormInputType.Input}
                    form={form}
                    hideLabel
                />
                <div className="mb-2 font-semibold text-xl">Make Default</div>
                <div className="mt-6 flex justify-center w-full">
                    {deployment.defaultDeployment ?
                        <Button disabled className="w-[450px] text-center bg-blue-900">
                            Default deployment set
                        </Button>
                        :
                        <Button 
                            disabled={formState.isDirty} 
                            className="w-[450px] text-center bg-blue-900" 
                            {...register('action')} 
                            onClick={() => setValue('action', 'setDefault')}
                        >
                            Log into this deployment automatically
                        </Button>
                    }
                </div>
                <Separator className="mt-6 mb-4"/>
            </CommonForm>
            {deleting && 
                <ConfirmAction 
                    open={deleting}
                    title={"Delete Deployment"}
                    description={`Are you sure you want to delete ${deployment.name}?`}
                    onConfirm={onDeleteConfirm}
                    onCancel={() => setDeleting(false)}
                />
            }
                
            <div className="mt-32 flex justify-center w-full">
                <button 
                    onClick={() => setDeleting(true)} 
                    className="mt-10 text-red-700"
                >
                    Delete deployment
                </button>
            </div>
        </div>
    )
}

enum DeploymentRole {
    User = "User",
    Admin = "Admin",
    AppUser = "AppUser",
    AppAdmin = "AppAdmin",
}

interface MemberRowProps {
    member: RaasifyUser;
    headerRow?: boolean;
    className?: string;
    onDelete?: (member: RaasifyUser) => void;
    onRoleChange?: (member: RaasifyUser, role: DeploymentRole) => void;
    deployment: any;
}

function MemberRow({member, className, headerRow, deployment}: MemberRowProps) {
    const name = member.firstName ? `${member.firstName!} ${member.lastName!}` : ' ';
    const email = member.email;
    const role = member.role;

    const form = useForm({});
    const { handleSubmit, watch } = form;

    const submit = useSubmit();
    
    useEffect(() => {
        const subscription = watch(() => {
            const data = {...deployment, memberId: member.id, memberRole: form.getValues().role, action: "updateRole"};
            submit(data, {
                action: "",
                method: "post",
                preventScrollReset: false,
                encType: "application/x-www-form-urlencoded",
                replace: false,
                relative: "route",
            });
        })
        return () => subscription.unsubscribe();
    }, [handleSubmit, watch]);


    const handleDelete = () => {
        const data = {...deployment, memberId: member.id, action: "removeMember"} as any;
        submit(data, {
            action: "",
            method: "post",
            preventScrollReset: false,
            encType: "application/x-www-form-urlencoded",
            replace: false,
            relative: "route",
        });
    }

    let values = deployment.role === 'Admin' 
        ? [DeploymentRole.Admin, DeploymentRole.User, DeploymentRole.AppAdmin, DeploymentRole.AppUser]
        : [DeploymentRole.AppAdmin, DeploymentRole.AppUser];

        return (
        <div className="inline-block">
            <div className={cn("flex my-2 p-2 text-sm", className)}>
                <div className="w-[250px]">
                    {name}
                </div>
                <div className="w-[400px] ml-4">
                    {email}
                </div>
                <div className="w-[130px] ml-4 mr-4 mb-0 h-1">
                    {headerRow ? <div>{role}</div> :
                        <CommonForm
                            instance={member}
                            form={form}
                            hideButtons
                        >
                            <CommonFormField
                                itemClass="w-[130px] -mt-4 mb-0"
                                name={"role"}
                                label={"role"}
                                description={"role"}
                                inputType={CommonFormInputType.Select}
                                form={form}
                                placeholder="Member role"
                                values={values}
                                hideLabel
                                hideSeparator
                            />
                        </CommonForm>
                    }
                </div>
                <CommonForm
                    instance={member}
                    form={form}
                    hideButtons
                >
                    <div className={"w-[50px] ml-4 text-xl cursor-pointer"} onClick={handleDelete}>
                        {!headerRow && <i className="fi fi-rr-trash"/>}
                    </div>
                </CommonForm>
            </div>
            {!headerRow && <Separator/>}
        </div>
    )
}

const emailSchema = z.object({
    email: z.string().email().nonempty(),
});

function DeploymentShare({deployment, onModeChange, mode, members}: DeploymentInfoProps) {
    const resolver = zodResolver(emailSchema);
    const form = useForm({resolver});
    const submit = useSubmit();

    const doSubmit = () => {
        const data = {...deployment, memberEmail: form.getValues().email, action: "invite"} as any;
        submit(data, {
            action: "",
            method: "post",
            preventScrollReset: false,
            encType: "application/x-www-form-urlencoded",
            replace: false,
            relative: "route",
        });
        form.reset();
    }    
    
    return (
        <div>
            <div className="flex flex-col text-sm">
                <div className="mt-6 mb-2 font-semibold text-xl">Manage User Access</div>
                <div className="mt-2 text-sm">
                    Give your teammates access to this deployment and start collaborating in real time.
                </div>
                <CommonForm
                    instance={deployment}
                    form={form}
                    hideButtons
                >
                    <div className="flex flex-row mt-4">
                        <CommonFormField
                            itemClass="-ml-4 w-[585px]"
                            name={"email"}
                            label={"email"}
                            description={"email"}
                            inputType={CommonFormInputType.Input}
                            form={form}
                            placeholder="Teammate's email address"
                            hideLabel
                            hideSeparator
                        />
                        <Button 
                            type="button"
                            className="mt-2.5 ml-2 w-[80px]"
                            onClick={doSubmit}
                        >
                            Invite
                        </Button>
                    </div>
                </CommonForm>
            </div>
            <div className="flex flex-col">
                <div className="mt-0 mb-4 font-semibold text-xl">Members</div>
                <div className="flex flex-col">
                    <MemberRow
                        key={`member_header`}
                        className="bg-gray-300 font-semibold mb-0"
                        deployment={deployment}
                        member={{
                            name: "Name",
                            email: "Email",
                            role: "Role",
                        } as any}
                        headerRow
                    />
                    {members && members.map((member: RaasifyUser, ix: number) => {
                        return (
                            <MemberRow
                                key={`member_${ix}`}
                                member={member}
                                deployment={deployment}
                            />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}


interface DeploymentProps {
    me: RaasifyUser;
    selectedDeployment: Deployment;
    members: Array<RaasifyUser>;
    services: Services;
}

export default function Deployments({me, selectedDeployment, members, services}: DeploymentProps) {
    //const { userPrefsCookie, me } = useOutletContext() as any;
    //const {deployments, user, selectedDeployment, members} 
    //    = useLiveLoader('./deploymentEvents') as LoaderData;
    const [filter, setFilter] = useState("");
    const [drawerMode, setDrawerMode] = useState(DrawerMode.Information);
    const userPrefsCookie = {} as any;
    const [deployments, setDeployments] = useState([] as Array<Deployment>);

    const fullScreenMode = userPrefsCookie && userPrefsCookie.screenMode === ScreenMode.Full;
    
    console.log({me});

    const requestAccess = !me.allowAddDeployment && deployments.length === 0;


    const getDeployments = async () => {
        const deployments = (await services.userData.get('/deployments')).data;
        console.log({deployments});
        setDeployments(deployments as Array<Deployment>);
    }

    useEffect(() => {
        getDeployments();
    }, []);

    return (
        <div className="relative h-full bg-white mx-4">
            {!fullScreenMode && !requestAccess &&
                <>
                    <Header label={"Deployments"} tagline={TAGLINE} className="mx-0 mb-3"/>
                    <Separator/>
                </>
            }
            {me.allowAddDeployment && <FilterAddNewToolbar
                className={"mx-0 mt-2"}
                filterValue={filter}
                onFilterChange={setFilter}
                onReset={() => setFilter("")}
                addNewLabel={"+ Add Deployment"}
                addNewRoute={"/deployments/add"}
            />}
            <div className="flex">
                <div className="flex flex-wrap content-start -ml-[0.2rem] my-[1rem]">
                    {requestAccess && 
                        <div className="ml-1">
                            <div className="text-2xl font-medium mt-0 mb-4">Request Access</div>
                            <div className="w-full text-gray-500">Please contact your administrator to request access to your organizational deployments.</div>
                        </div>
                    }
                    {deployments && deployments.map((deployment: Deployment, ix: number) => {
                        if (isEmpty(filter) || deployment.name?.toLowerCase().includes(filter.toLowerCase())) {
                            return (
                                <DeploymentCard
                                    key={deployment.id}
                                    deployment={deployment}
                                    isSelected={selectedDeployment?.id === deployment.id}
                                />
                            )
                        }
                    })}
                </div>
                {selectedDeployment &&
                    <DeploymentSheet
                        deployment={selectedDeployment}
                        mode={drawerMode}
                        onModeChange={setDrawerMode}
                        showDescription={drawerMode !== DrawerMode.Edit}
                        cardClass={drawerMode === DrawerMode.Share ? "w-[720px]" : "w-[500px]"}
                    >
                        <>
                            {selectedDeployment && drawerMode === DrawerMode.Information &&
                                <DeploymentInfo
                                    deployment={selectedDeployment}
                                    token={me.accessToken as any}
                                    onModeChange={setDrawerMode}
                                    mode={drawerMode}
                                />
                            }
                            {selectedDeployment && selectedDeployment?.role === 'Admin' && !selectedDeployment?.invitee && drawerMode && drawerMode === DrawerMode.Edit &&
                                <DeploymentEdit
                                    deployment={selectedDeployment}
                                    token={me.accessToken as any}
                                    onModeChange={setDrawerMode}
                                    mode={drawerMode}
                                />
                            }
                            {selectedDeployment && (selectedDeployment?.role === 'Admin' || selectedDeployment?.role === 'AppAdmin') && !selectedDeployment?.invitee && drawerMode === DrawerMode.Share &&
                                <DeploymentShare
                                    deployment={selectedDeployment}
                                    token={me.accessToken as any}
                                    onModeChange={setDrawerMode}
                                    mode={drawerMode}
                                    members={members}
                                />
                            }
                        </>
                    </DeploymentSheet>
                }
            </div>
        </div>
    );
}
