import type { ColumnDef, Row } from "@tanstack/react-table"
import * as React from "react";
import { z } from "zod"

import { CommonForm, CommonFormField, CommonFormInputType } from "../../components/form/common-form";
import { Deployment } from "memecoin-models-specification-ts/user/Deployment";
import type { Attribute } from "common-models-ts";
import { EditorFormProps, TypeSpec } from "../../util/types";

const specification = Deployment.specification;
const attributes = specification.attributes as { [p: string]: Attribute };

const {
    name,
    description,
    status,
    associatedAutomationPolicyName,
    associatedEdgeName,
    associatedEndpointName,
    associatedEndpointId,
    associatedAutomationPolicyId,
    associatedSourceAutomationId,
    associatedAutomationPolicySchedule,
    associatedAutomationPolicyCronScheduleName,
} = attributes;

const tagLine = "Deployments are application instances with their own set of configurations and policies.";

/**
    Schema to validate the editor form
 */
const editorSchema = z.object({
    name: z.string().min(2),
    description: z.any(),
    associatedEndpointId: z.string(),
    associatedAutomationPolicyId: z.string(),
    associatedSourceAutomationId: z.string().nullable().optional(),
})

function EditorForm(props: EditorFormProps<Deployment>) {
    const {
        form,
        data,
        onSaveClick,
    } = props;

    return (
        <CommonForm
            instance={data}
            form={form}
            onSaveClick={onSaveClick}
        >
            <CommonFormField
                name={"name"}
                label={"Name"}
                description={"Deployment name"}
                inputType={CommonFormInputType.Input}
                form={form}
            />
            <CommonFormField
                name={"description"}
                label={"Description"}
                description={"Deployment description"}
                inputType={CommonFormInputType.Textarea}
                form={form}
            />
            <CommonFormField
                name={"subdomain"}
                label={"Subdomain"}
                description={"A unique subdomain name for this deployment"}
                inputType={CommonFormInputType.Input}
                form={form}
            />
        </CommonForm>
    )
}

export const DeploymentTypeSpec: TypeSpec = {
    EditorForm,
    editorSchema,
    tagLine,
}