import { Input } from "../../components/ui/input";
import { Textarea } from "../../components/ui/textarea";
import { Separator } from "../../components/ui/separator";
import { cn } from "../../lib/utils";
import { Button } from "../../components/ui/button";
import { DefaultValues, FieldValues, Path, RegisterOptions, useForm, useFormState } from "react-hook-form";
import { BaseSyntheticEvent, ReactNode, useEffect, useState } from "react";
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel } from "../../components/ui/form";
import * as React from "react";
import * as react_hook_form from "react-hook-form";
import { Checkbox } from "../../components/ui/checkbox";
import { Select, SelectContent, SelectItem, SelectTrigger } from "../../components/ui/select";
import { Popover, PopoverContent, PopoverTrigger } from "../../components/ui/popover";
import { CalendarIcon, CaretSortIcon, CheckIcon } from "@radix-ui/react-icons";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem } from "../ui/command";
import { Calendar } from "../ui/calendar";
import { format, formatISO, parseISO } from "date-fns"
import { Blocker, BlockerFunction } from "@remix-run/router";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "../../components/ui/dialog";
import { getLogger, isEmpty } from "../../utils/util";
import { CLASSES } from "../../components/form/common-form-defaults";
import { toast as notify } from "react-toastify";
import reactCopyToClipboard from "react-copy-to-clipboard";
import Dropzone from "../ui/dropzone";
import { Link } from "react-router-dom";


export type RelatedInstances = { [specificationName: string]: {[id: string]: any} };

const { CopyToClipboard } = reactCopyToClipboard;

const logger = getLogger();


export enum CommonFormInputType {
    Input,
    Textarea,
    Select,
    Switch,
    JSON,
    RelatedInstanceSelect,
    DateTime,
    CommandSelect,
    Editor,
    DropZone = 9,
}

export interface CommonSelectProps {
    onValueChange: (value: string) => void;
    defaultValue?: string;
    disabled?: boolean;
    valueClass?: string;
    values: Array<string>;
    keyPrefix: string;
    placeholder?: string;
    field: any;
    showNotSelected?: boolean;
}

const NOT_SELECTED = "___not-selected___";

export function CommonSelect({
    onValueChange,
    defaultValue,
    disabled,
    valueClass,
    values,
    keyPrefix,
    placeholder,
    field,
    showNotSelected,
}: CommonSelectProps) {

    const triggerClassName = placeholder && isEmpty(defaultValue) ? cn(valueClass, "text-gray-500") : valueClass;
    const _defaultValue = placeholder && isEmpty(defaultValue) ? placeholder : !isEmpty(defaultValue) ? defaultValue : "Select ...";

    return (
        <Select
            onValueChange={(v: any) => {
                const value = showNotSelected && v === NOT_SELECTED ? null : isEmpty(v) ? null : v;
                onValueChange(value)
            }}
            defaultValue={defaultValue}
            disabled={disabled}
            value={field.value}
        >
            <FormControl>
                <SelectTrigger className={triggerClassName}>
                    {_defaultValue}
                </SelectTrigger>
            </FormControl>
            <SelectContent
                className={valueClass}
            >
                {showNotSelected && <SelectItem
                    key={`${keyPrefix}_not_selected`}
                    value={NOT_SELECTED}
                >
                    --- Not Selected ---
                </SelectItem>}
                {values && values.map((value, index) => {
                    return (
                        <SelectItem
                            key={`${keyPrefix}_${index}`}
                            value={value}
                        >
                            {value}
                        </SelectItem>
                    )
                })}
            </SelectContent>
        </Select>
    );
}

export interface CommonRelatedInstanceSelectProps {
    onValueChange: (value: string) => void;
    defaultValue?: string;
    disabled?: boolean;
    buttonClass?: string;
    contentClass?: string;
    values: Array<any>;
    labelField: string;
    valueField: string;
    keyPrefix: string;
}


export function CommonRelatedInstanceSelect({
    onValueChange,
    defaultValue,
    disabled,
    buttonClass,
    contentClass,
    values,
    keyPrefix,
    labelField,
    valueField,
}: CommonRelatedInstanceSelectProps) {
    const [open, setOpen] = useState(false);

    const defaultRelated = defaultValue && values
        ? values.find((item) => item[valueField] === defaultValue)
        : undefined;

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger disabled={disabled} asChild>
                <FormControl>
                    <Button
                        variant="outline"
                        role="combobox"
                        className={cn(
                            "justify-between",
                            !defaultValue && " ", buttonClass
                        )}
                    >
                        {defaultRelated
                            ? defaultRelated[labelField]
                            : "Select ..."}
                        <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                </FormControl>
            </PopoverTrigger>
            <PopoverContent className={cn("p-0", contentClass)}>
                <Command>
                    <CommandInput placeholder="Search ..." />
                    <CommandEmpty>No result found.</CommandEmpty>
                    <CommandGroup className={"CommandGroup"}>
                        {values && values.map((item, index) => (
                            <CommandItem
                                value={item[labelField]}
                                key={`${keyPrefix}_${index}`}
                                onSelect={() => {
                                    onValueChange(item[valueField]);
                                    setOpen(false);
                                }}
                            >
                                <CheckIcon
                                    className={cn(
                                        "mr-2 h-4 w-4",
                                        item[valueField] === defaultValue
                                            ? "opacity-100"
                                            : "opacity-0"
                                    )}
                                />
                                {item[labelField]}
                            </CommandItem>
                        ))}
                    </CommandGroup>
                </Command>
            </PopoverContent>
        </Popover>
    );
}

export interface CommonCommandSelectProps {
    onValueChange: (value: string) => void;
    defaultValue?: string;
    disabled?: boolean;
    buttonClass?: string;
    contentClass?: string;
    values: Array<any>;
    keyPrefix: string;
}

export function CommonCommandSelect({
    onValueChange,
    defaultValue,
    disabled,
    buttonClass,
    contentClass,
    values,
    keyPrefix,
}: CommonCommandSelectProps) {
    const [open, setOpen] = useState(false);

    const _defaultValue = defaultValue && values
        ? values.find((item) => item === defaultValue)
        : undefined;

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger disabled={disabled} asChild>
                <FormControl>
                    <Button
                        variant="outline"
                        role="combobox"
                        className={cn(
                            "justify-between",
                            !defaultValue && " ", buttonClass
                        )}
                    >
                        {_defaultValue
                            ? _defaultValue
                            : "Select ..."}
                        <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                </FormControl>
            </PopoverTrigger>
            <PopoverContent className={cn("p-0", contentClass)}>
                <Command>
                    <CommandInput placeholder="Search ..." />
                    <CommandEmpty>No result found.</CommandEmpty>
                    <CommandGroup className={"CommandGroup overflow-y-scroll"}>
                        {values && values.map((item, index) => (
                            <CommandItem
                                value={item}
                                key={`${keyPrefix}_${index}`}
                                onSelect={() => {
                                    onValueChange(item);
                                    setOpen(false);
                                }}
                            >
                                <CheckIcon
                                    className={cn(
                                        "mr-2 h-4 w-4",
                                        item === defaultValue
                                            ? "opacity-100"
                                            : "opacity-0"
                                    )}
                                />
                                {item}
                            </CommandItem>
                        ))}
                    </CommandGroup>
                </Command>
            </PopoverContent>
        </Popover>
    );
}

export interface CommonDateTimeProps {
    onValueChange: (value: any) => void;
    defaultValue?: any;
    disabled?: boolean;
    buttonClass?: string;
    contentClass?: string;
    values: Array<any>;
    labelField: string;
    valueField: string;
    keyPrefix: string;
}


export function CommonDateTime({
    onValueChange,
    defaultValue,
    disabled,
    buttonClass,
    contentClass,
    values,
    keyPrefix,
    labelField,
    valueField,
}: CommonDateTimeProps) {

    const [open, setOpen] = useState(false);
    const [timeValue, setTimeValue] = React.useState<string>(defaultValue ? format(defaultValue, "HH:mm") : "00:00");
    const [selected, setSelected] = React.useState<Date | undefined>(defaultValue ? parseISO(defaultValue) : undefined);

    const handleTimeChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const time = e.target.value;
        if (!selected) {
            setTimeValue(time);
            return;
        }
        const [hours, minutes] = time.split(':').map((str) => parseInt(str, 10));
        const newSelectedDate = new Date(
            selected.getFullYear(),
            selected.getMonth(),
            selected.getDate(),
            hours,
            minutes
        );
        onValueChange(formatISO(newSelectedDate));
        setSelected(newSelectedDate);
        setTimeValue(time);
    };

    const handleDateChange = (value: Date) => {
        const [hours, minutes] = timeValue.split(':').map((str) => parseInt(str, 10));
        const newSelectedDate = new Date(
            value.getFullYear(),
            value.getMonth(),
            value.getDate(),
            hours,
            minutes
        );
        onValueChange(formatISO(newSelectedDate));
        setSelected(newSelectedDate);
    };

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger disabled={disabled} asChild>
                <FormControl>
                    <Button
                        variant="outline"
                        role="combobox"
                        className={cn(
                            "justify-between",
                            !selected && "text-muted-foreground", buttonClass
                        )}
                    >
                        {selected ? (
                            format(selected, "PPP pp")
                        ) : (
                            <span>Pick a date</span>
                        )}
                        <CalendarIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                </FormControl>
            </PopoverTrigger>
            <PopoverContent className={cn("p-0 flex justify-center", contentClass)}>
                <Calendar
                    mode="single"
                    selected={selected}
                    onSelect={(value) => {
                        handleDateChange(value as Date);
                    }}
                    disabled={(date) =>
                        date < new Date()
                    }
                    footer={
                        <>
                            <p className="mt-4 text-center">
                                Time:{' '}
                                <input
                                    type="time"
                                    value={timeValue}
                                    onChange={handleTimeChange}
                                />
                            </p>
                        </>
                    }
                />
            </PopoverContent>
        </Popover>
    );
}

export interface CommonFormFieldProps {
    outerClass?: string;
    itemClass?: string;
    labelDivClass?: string;
    labelClass?: string;
    valueDivClass?: string;
    valueClass?: string;
    placeholder?: string;
    name: string;
    label?: string;
    description?: string;
    inputType?: CommonFormInputType;
    form: react_hook_form.UseFormReturn<any, any, undefined>;
    hideSeparator?: boolean;
    disabled?: boolean;
    values?: string[];
    buttonClass?: string;
    contentClass?: string;
    type?: string;
    relatedInstances?: RelatedInstances;
    hidden?: (data: any, relatedInstances: RelatedInstances) => boolean;
    showNotSelected?: boolean;
    hideLabel?: boolean;
    inSection?: boolean;
    style?: any;
    onChange?: (event: any) => void;
    value?: any;
    elementUnderValue?: ReactNode;
    children?: ReactNode;
}


interface CopyToClipboardButtonProps {
    className?: string;
    label: string;
    value: any;
}

function CopyToClipboardButton({ className, label, value }: CopyToClipboardButtonProps) {
    return (
        <CopyToClipboard
            onCopy={() => notify(`${label} copied to clipboard`, {})}
            text={typeof value === 'object'
                ? JSON.stringify(value, null, 4)
                : value}>
            <i className={cn("fi fi-rr-copy cursor-pointer text-blue-900", className)} />
        </CopyToClipboard>
    )
}


export function CommonFormField({
    outerClass,
    itemClass,
    labelDivClass,
    labelClass,
    valueDivClass,
    valueClass,
    name,
    label,
    description,
    placeholder,
    inputType,
    form,
    hideSeparator,
    disabled,
    values,
    buttonClass,
    contentClass,
    type,
    relatedInstances,
    hidden,
    showNotSelected,
    hideLabel,
    inSection,
    style,
    onChange,
    value,
    elementUnderValue,
    children,
}: CommonFormFieldProps) {

    const _inputType = inputType ? inputType : CommonFormInputType.Input;
    const { register, formState } = form;
    const { errors } = formState;

    useEffect(() => {
        // Set the initial value to boolean (otherwise it could be a string)
        if (_inputType === CommonFormInputType.Switch) {
            form.setValue(name, Boolean(form.getValues()[name]));
        }
    }, []);

    const _type = type ? type : "text";

    if (hidden && hidden(form.getValues(), relatedInstances || {})) {
        return <></>
    }

    const setValueAs = (v: string) => {
        if (_type === "number") {
            return isEmpty(v) ? null : parseFloat(v);
        }
        return isEmpty(v) ? null : v;
    }

    const toBase64 = (file: File) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });
        
    async function handleOnDrop(attributeName: string, acceptedFiles: FileList | null, onChange: any) {
        if (acceptedFiles && acceptedFiles.length > 0) {
            const allowedTypes = [
                { name: "images", types: ["image/jpg", "image/gif", "image/png", "image/jpeg"] },
            ];
            const fileType = allowedTypes.find((allowedType) =>
                allowedType.types.find((type) => type === acceptedFiles[0].type)
            );
            if (!fileType) {
                form.setValue(attributeName, null);
                form.setError(attributeName, {
                    message: "File type is not valid",
                    type: "typeError",
                });
            }
            else if (acceptedFiles[0].size > 500_000) {
                form.setValue(attributeName, null);
                form.setError(attributeName, {
                    message: "File size is too large. Max size is 500KB",
                    type: "typeError",
                });
            }
            else {
                const base64Image = await toBase64(acceptedFiles[0]);
                if(onChange) {
                    onChange(base64Image);
                }
                form.setValue(attributeName, base64Image, { shouldDirty: true });
                form.clearErrors(attributeName);
            }
        }
        else {
            form.setValue(attributeName, null);
            form.setError(attributeName, {
                message: "File is required",
                type: "typeError",
            });
        }
    }

    // Standard widths
    const _labelWidthClass = CLASSES.normal.label;
    const _labelDescriptionWidthClass = CLASSES.normal.description;
    const _valueWidthClass = inSection ? CLASSES.inSection.value : CLASSES.normal.value;

    // In Section widths
    const _labelDivClass = inSection
        ? cn(CLASSES.inSection.label, labelDivClass)
        : labelDivClass;
    const _valueClass = inSection
        ? cn(CLASSES.inSection.value, valueClass)
        : valueClass;
    const _buttonClass = inSection
        ? cn(CLASSES.inSection.button, buttonClass)
        : cn(CLASSES.normal.button, buttonClass);
    const _contentClass = inSection
        ? cn(CLASSES.inSection.content, contentClass)
        : cn(CLASSES.normal.content, contentClass);

    return (
        <div className={cn("mb-6", outerClass)}>
            <div className="mb-6">
                <FormField
                    control={form.control}
                    name={name}
                    render={({ field }) => {
                        const _onChange = (e: any) => {
                            field.onChange(e);
                            if (onChange) {
                                onChange(e);
                            }
                        }

                        return (
                            <FormItem className={cn("flex", itemClass)}>
                                <>
                                    {!hideLabel &&
                                        <div className={cn(_labelWidthClass, _labelDivClass)}>
                                            <FormLabel className={cn("text-lg font-semibold text-gray-700", labelClass)}>{label}</FormLabel>
                                            <CopyToClipboardButton className="ml-1.5 text-sm" label={label as string} value={field.value} />
                                            <FormDescription className={cn("text-gray-500", _labelDescriptionWidthClass)}>
                                                {description}
                                            </FormDescription>

                                        </div>
                                    }
                                    <FormControl className={cn("mt-2.5 ml-4", _valueWidthClass, valueDivClass)}>
                                        <div>
                                            {_inputType === CommonFormInputType.Input && value &&
                                                <Input
                                                    className={cn("", _valueClass)}
                                                    placeholder={placeholder}
                                                    disabled={disabled}
                                                    type={_type}
                                                    step="0.1"
                                                    value={value}
                                                />
                                            }
                                            {_inputType === CommonFormInputType.Input && !value &&
                                                <Input
                                                    className={cn("", _valueClass)}
                                                    placeholder={placeholder}
                                                    disabled={disabled}
                                                    type={_type}
                                                    step="0.1"
                                                    {...register(name, { setValueAs })}
                                                />
                                            }
                                            {_inputType === CommonFormInputType.Textarea &&
                                                <Textarea
                                                    rows={8}
                                                    className={cn("", _valueClass)}
                                                    placeholder={placeholder}
                                                    disabled={disabled}
                                                    {...register(name, { setValueAs })}
                                                />
                                            }
                                            {_inputType === CommonFormInputType.Editor &&
                                                <Textarea
                                                    rows={8}
                                                    className={cn("", _valueClass)}
                                                    placeholder={placeholder}
                                                    disabled={disabled}
                                                    {...register(name, { setValueAs })}
                                                />
                                            }
                                            {_inputType === CommonFormInputType.Switch &&
                                                <Checkbox
                                                    className={cn("", valueClass)}
                                                    checked={field.value === true}
                                                    onCheckedChange={_onChange}
                                                    disabled={disabled}
                                                    {...register(name)}
                                                />
                                            }
                                            {_inputType === CommonFormInputType.Select &&
                                                <CommonSelect
                                                    values={values as string[]}
                                                    defaultValue={field.value}
                                                    disabled={disabled}
                                                    valueClass={_valueClass}
                                                    keyPrefix={field.name}
                                                    placeholder={placeholder}
                                                    field={field}
                                                    showNotSelected={showNotSelected}
                                                    onValueChange={_onChange}
                                                />
                                            }
                                            {_inputType === CommonFormInputType.CommandSelect &&
                                                <CommonCommandSelect
                                                    values={values as string[]}
                                                    onValueChange={_onChange}
                                                    defaultValue={field.value}
                                                    disabled={disabled}
                                                    buttonClass={_buttonClass}
                                                    contentClass={_contentClass}
                                                    keyPrefix={field.name}
                                                />
                                            }
                                            {_inputType === CommonFormInputType.RelatedInstanceSelect &&
                                                <CommonRelatedInstanceSelect
                                                    values={values as string[]}
                                                    onValueChange={_onChange}
                                                    defaultValue={field.value}
                                                    disabled={disabled}
                                                    buttonClass={_buttonClass}
                                                    contentClass={_contentClass}
                                                    keyPrefix={field.name}
                                                    valueField={"id"}
                                                    labelField={"name"}
                                                />
                                            }
                                            {_inputType === CommonFormInputType.DateTime &&
                                                <CommonDateTime
                                                    values={values as string[]}
                                                    onValueChange={_onChange}
                                                    defaultValue={field.value}
                                                    disabled={disabled}
                                                    buttonClass={_buttonClass}
                                                    contentClass={_contentClass}
                                                    keyPrefix={field.name}
                                                    valueField={"id"}
                                                    labelField={"name"}
                                                />
                                            }
                                            {_inputType === CommonFormInputType.DropZone &&
                                                <Dropzone
                                                    {...field}
                                                    dropMessage="Drag your logo here or click to select"
                                                    handleOnDrop={(fileList) => handleOnDrop(name, fileList, onChange)}
                                                    {...register(name)}
                                                >
                                                    {children}
                                                </Dropzone>
                                            }
                                            {elementUnderValue && elementUnderValue}
                                            {errors && errors[name] && <p className="mt-2 text-red-600">{errors[name]?.message as any}</p>}
                                        </div>
                                    </FormControl>
                                </>
                            </FormItem>
                        )
                    }}
                />
            </div>
            {!hideSeparator && <Separator />}
        </div>
    )
}

export interface CommonFormProps {
    action?: string;
    instance: any;
    form: react_hook_form.UseFormReturn<any, any, undefined>;
    children?: ReactNode;
    hideButtons?: boolean;
    hideSaveButton?: boolean;
    disabled?: boolean;
    initialUpdate?: any; // Used to set initial values after defaults already set. Ie, clone can set "<name> (copy)"
    onSaveClick?: () => void;
    buttonClass?: string;
    cancelTo?: string;
    saveButtonLabel?: string;
    disableBlocker?: boolean;
}

function ConfirmNavigation({ blocker }: { blocker: Blocker }) {
    if (blocker.state === "blocked") {
        return (
            <Dialog open onOpenChange={() => blocker.reset()}>
                <DialogContent className="py-5 pl-10 pr-10">
                    <DialogHeader>
                        <div>
                            <DialogTitle className="py-5 text-center">Unsaved Changes!</DialogTitle>
                            <DialogDescription className="py-5 text-lg text-center">
                                You have unsaved changes.<br />
                                Are you sure you want to discard changes?
                            </DialogDescription>
                        </div>
                        <div className="flex py-5 justify-center">
                            <Button className={cn(`bg-green-700 text-lg`, CLASSES.dialog.button)} onClick={() => blocker.reset()}>
                                Continue Editing
                            </Button>
                            <Button className={cn(`ml-8 bg-red-700 text-lg`, CLASSES.dialog.button)} onClick={() => blocker.proceed()}>
                                Discard Changes
                            </Button>
                        </div>
                    </DialogHeader>
                </DialogContent>
            </Dialog>
        );
    }
    return <></>
}


export interface ConfirmActionProps {
    open: boolean;
    onCancel: () => void;
    onConfirm: () => void;
    title: string;
    description: string;
}

export function ConfirmAction({ open, onCancel, onConfirm, title, description }: ConfirmActionProps) {
    return (
        <Dialog open={open} onOpenChange={() => onCancel()}>
            <DialogContent className="py-5 pl-10 pr-10">
                <DialogHeader>
                    <div>
                        <DialogTitle className="py-5 text-center">{title}</DialogTitle>
                        <DialogDescription className="py-5 text-lg text-center">
                            {description}
                        </DialogDescription>
                    </div>
                    <div className="flex py-5 justify-center">
                        <Button className={cn(`bg-green-700 text-lg`, CLASSES.dialog.button)} onClick={onCancel}>
                            Cancel
                        </Button>
                        <Button className={cn(`ml-8 bg-red-700 text-lg`, CLASSES.dialog.button)} onClick={onConfirm}>
                            Confirm
                        </Button>
                    </div>
                </DialogHeader>
            </DialogContent>
        </Dialog>
    );
}

export function CommonForm({
    saveButtonLabel,
    disableBlocker,
    action,
    instance,
    children,
    form,
    disabled,
    hideButtons,
    hideSaveButton,
    initialUpdate,
    onSaveClick,
    buttonClass,
    cancelTo,
}: CommonFormProps) {
    const { control, handleSubmit, reset, setValue } = form;

    const { isDirty } = useFormState({ control });

    const _cancelTo = cancelTo ? cancelTo : '../../';

    logger.info(JSON.stringify({updated: instance.updated, instance, isDirty, dirtyFields: form.formState.dirtyFields, values: form.getValues(), defaultValues: form.control._defaultValues}, null, 4));

    useEffect(() => {
        if (form && (instance.updated || instance.__updated) && !initialUpdate) {
            reset(instance);
        }
        if (initialUpdate) {
            for (const [key, value] of Object.entries(initialUpdate)) {
                setValue(key, value, { shouldDirty: true });
            }
        }
    }, [instance])

    const onHandleSubmit = (e: BaseSyntheticEvent) => {
        //console.log("onHandleSubmit", form.getValues());
        for (const [key, value] of Object.entries(form.getValues())) { 
            form.setValue(key, value === undefined ? null : value);
        }
        logger.info({ e, values: form.getValues(), fields: form.control._fields });
        //return handleSubmit(e);
    }

    const _action = action ? action : '.';

    const _saveButtonLabel = saveButtonLabel ? saveButtonLabel : "Save";

    return (
        <div>
            <Form {...form as any} action={_action} method="POST" encType="multipart/form-data">
                {children}
                {!(hideButtons || disabled) && <div className={"flex mt-12 "}>
                        <Link to={_cancelTo as string}>
                            <Button className={cn(`bg-red-700 text-lg`, CLASSES.submit.button, buttonClass)}>
                                <i className="fi fi-sr-undo mr-3 text-lg mt-0.5" />
                                Cancel
                            </Button>
                        </Link>
                        {!hideSaveButton &&
                            <Button disabled={false} className={cn(`ml-8 bg-green-700 text-lg`, CLASSES.submit.button, buttonClass)}
                                onClick={onSaveClick}>
                                <i className="fi fi-sr-disk mr-3 text-lg mt-1" />
                                {_saveButtonLabel}
                            </Button>}
                    </div>}
            </Form>
        </div>
    )
}


export interface CommonFormSectionProps {
    className?: string;
    label: string;
    description: string;
    children?: ReactNode;
    labelOwnRow?: boolean;
}

export function CommonFormSection({ className, label, description, labelOwnRow, children }: CommonFormSectionProps) {
    if (labelOwnRow) {
        return (
            <div key={`CommonFormSection-${label}`} className={className}>
                <div className={"my-6"}>
                    <div className={cn(CLASSES.inSection.label)}>
                        <div className={cn("text-lg font-semibold text-gray-700")}>{label}</div>
                        <div className={cn(`text-gray-500`, CLASSES.inSection.description)}>{description}</div>
                    </div>
                    <div className={cn("mt-4")}>
                        {children}
                    </div>
                </div>
                <Separator />
            </div>
        )
    }
    else {
        return (
            <div key={`CommonFormSection-${label}`} className={className}>
                <div className={"flex my-6"}>
                    <div className={cn(CLASSES.inSection.label)}>
                        <div className={cn("text-lg font-semibold text-gray-700")}>{label}</div>
                        <div className={cn(`text-gray-500`, CLASSES.inSection.description)}>{description}</div>
                    </div>
                    <div className={cn("mt-1.5 ml-4")}>
                        {children}
                    </div>
                </div>
                <Separator />
            </div>
        )
    }
}
