import {cn} from "../../lib/utils";


interface HeaderProps {
    label: string;
    tagline: string
}

export default function Header({className, label, tagline, ...props}: React.HTMLAttributes<HTMLElement> & HeaderProps) {
    return (
       <div className={cn("-mt-1", className)}>
           <div className="text-2xl font-medium">{label}</div>
           <div className="mt-2 text-gray-500">{tagline}</div>
       </div>
    );
}
