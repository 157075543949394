import React from "react";
import {BrowserRouter as Router,} from "react-router-dom";
import { AppContainer } from "./AppContainer";
import UserService from "./services/UserService";

import './index.css';

import { createRoot } from 'react-dom/client';
import store from './redux/store'
import { Provider } from 'react-redux'


const appContainer = document.getElementById('app');
const root = createRoot(appContainer!);

const renderApp = () => root.render(
    <Router>
        <Provider store={store}>
            <AppContainer />
        </Provider>
    </Router>
);

UserService.initKeycloak(renderApp);
