import React, { FC, useEffect, useState } from "react";

import RenderOnAuthenticated from "./components/authenticated/RenderOnAuthenticated";
import RenderOnAnonymous from "./components/authenticated/RenderOnAnonymous";
import NotAllowed from "./components/authenticated/NotAllowed";
import {User} from "memecoin-models-specification-ts/user/User";
import {Deployment} from "memecoin-models-specification-ts/user/Deployment";
import {EventService} from "./services/EventService";
import {Selected} from "./model/Selected";
import { CORE_LOGIC, Services, USER_LOGIC } from "./services/Services";

import {core} from 'memecoin-models-specification-json/index.json';

import {InstanceEditor} from "./pages/InstanceEditor";

import { Navigate, Route, Routes } from "react-router-dom";
import Deployments from "./pages/Deployments";
import Workbench from "./pages/Workbench";
import { SideBar } from "./components/application/side-bar";
import { TopBar } from "./components/application/top-bar";
import { InstanceAction, Service } from "./util/types";

const services = new Services(core.specifications);

export const AppContainer: FC = (props) => {
    const [instanceFilters, setInstanceFilters] = useState<{[index: string]: string}>({});
    const [selectedDeployment, setSelectedDeployment] = useState<Deployment|undefined>();
    const [selectedPage, setSelectedPage] = useState<string|undefined>();
    const [loggedIn, setLoggedIn] = useState<boolean>(false);
    const [selected, setSelected] = useState<Selected>();
    const [params, setParams] = useState<URLSearchParams>();
    const [me, setMe] = useState<User>();

    const deployment = selectedDeployment as Deployment
    const userPrefsCookie = {};
    const userToken = {} as any;
    const instance = {} as any;


    const getMe = async () => {
        const user = (await services.userData.get('/me')).data;
        console.log({user});
        setMe(user as User);
    }

    useEffect(() => {
        getMe();
    }, []);

    if(!me) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <RenderOnAuthenticated>
                <div className={'flex'}>
                    <SideBar deployment={deployment} me={me} userPrefsCookie={userPrefsCookie}/>
                    <div className="w-full flex flex-col">
                        <TopBar
                            className="bg-gblue-200 h-10 mb-2 px-4"
                            deployment={deployment}
                            user={me}
                            userToken={userToken}
                            instance={instance}
                            userPrefsCookie={userPrefsCookie}
                        />
                        <div className={''}>
                            <Routes>
                                <Route 
                                    path="/" 
                                    element={
                                        <Navigate 
                                            to="/deployments" 
                                            replace 
                                        />
                                    } 
                                />
                                <Route path="/deployments" element={
                                    <Deployments
                                        me={me}
                                        selectedDeployment={selectedDeployment as Deployment}
                                        members={me.members as User[]}
                                        services={services}
                                    />}
                                />
                                <Route path="/deployments/add" element={
                                    <InstanceEditor
                                        service={Service.USER}
                                        type={Deployment}
                                        action={InstanceAction.ADD}
                                        returnRoute="/deployments"
                                    />}
                                />
                                <Route 
                                    path="/deployments/:deploymentId" 
                                    element={
                                        <Navigate 
                                            to={`/deployments/${selectedDeployment?.id}/xinfluencers`}
                                        />
                                    }
                                />
                                <Route
                                    path="/deployments/:deploymentId/workbench/*"
                                    element={
                                        <Workbench

                                        />
                                    }
                                />
                            
                            </Routes>
                        </div>
                    </div>
                </div>
                <EventService
                    instanceFilters={instanceFilters}
                    service={CORE_LOGIC}
                    dataService={services.data}
                    deployment={selectedDeployment}
                    deploymentMode={true}
                />
                <EventService
                    instanceFilters={instanceFilters}
                    service={USER_LOGIC}
                    dataService={services.userData}
                />
            </RenderOnAuthenticated>
            <RenderOnAnonymous>
                <NotAllowed/>
            </RenderOnAnonymous>
        </>
    );
}
