import pino from 'pino';
import {format, parseISO} from "date-fns"

export const convertBool = (value: boolean) => value ? 'Yes' : 'No'

export const toTitleCase = (text: string) => text.toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');

export const getById = (list: Array<{[index: string]: any}>, id: string|null) => {
    if(list) {
        for(const item of list) {
            if(item.id === id) {
                return item;
            }
        }
    }
    return undefined;
}

export const getByAttributeValue = (list: Array<{[index: string]: any}>, attributeName: string, attributeValue: any) => {
    if(list) {
        for(const item of list) {
            if(item[attributeName] === attributeValue) {
                return item;
            }
        }
    }
    return undefined;
}

export const getIndex = (list: Array<{[index: string]: any}>, id: string|null) => {
    for(let i=0; i<list.length; i++) {
        if(list[i].id === id) {
            return i;
        }
    }
}

export const removeEmptyAttributes = (obj: {[index: string]: any}) => {
    if(obj) {
        const _obj: any = {};
        for (const [key, value] of Object.entries(obj)) {
            if (value !== undefined && value !== null && value !== "") {
                _obj[key] = value;
            }
        }
        return _obj;
    }
    else {
        return obj;
    }
}   


export const isEmpty = (value: string|undefined) => {
    return value === null || value === undefined || value === "";
}


export const getLogger = () => {
    return pino();
}

export const formatDate = (v: string) => {
    if(!v) {
        return v;
    }
    const date = parseISO(v);
    const localDateString = format(date, "yyyy-MM-dd HH:mm:ss.SSS")
    return localDateString;
}

export const formatDateNoSecs = (v: string) => {
    const date = parseISO(v);
    const localDateString = format(date, "yyyy-MM-dd HH:mm")
    return localDateString;
}

export const formatDateSecs = (v: string) => {
    const date = parseISO(v);
    const localDateString = format(date, "yyyy-MM-dd HH:mm:ss")
    return localDateString;
}


export const formatJSDateSeconds = (date: Date) => {
    return format(date, "yyyy-MM-dd HH:mm:ss")
}


/*
import {DateTime} from 'luxon';
const formatDateShort = (v: string) => {
    const date = DateTime.fromISO(v, {zone: 'utc'});
    const dateStr = date.toFormat("yyyy-MM-dd HH:mm")
    return dateStr;
}
*/


export const queryStringToJSON = (params: string) => {
    const pairs: string[] = params.split('&');
    const result: any = {};
    pairs.forEach((pair) => {
        const pairArray = pair.split('=');
        result[pairArray[0]] = pairArray[1];
    });
    return result;
}

export function tryFormattedDate(value: string) {
    try {
        return formatDateSecs(value);
    }
    catch (e) {
        return value;
    }
}


export const stringify = (object: unknown, {preventCircular=false, indent=2} = {}): string => {
    let cache: Array<unknown>|undefined= [];

    const replacer = (_key: string, value: unknown) =>
        typeof value === 'object' && value !== null
            ? cache?.includes(value)
                ? undefined // Duplicate reference found, discard key
                : cache?.push(value) && value // Store value in our collection
            : value;

    const replacerFunction = preventCircular ? replacer: null;

    let retVal;
    try {
        retVal = JSON.stringify(
            object,
            // @ts-ignore
            replacerFunction,
            indent
        );
    }
    catch (err) {
        if (err instanceof Error && err.message.includes('Converting circular structure to JSON')) {
            // force using the replacer function to eliminate circulars
            retVal = JSON.stringify(
                object,
                replacer,
                indent
            );
        }
    }
    cache = undefined;
    return retVal as string;
}
