

import React, {FC, ReactElement, useRef} from 'react'

export interface AuthenticatedLinkProps {
    url: string;
    children: ReactElement;
    className?: string;
    token: string;
}

export const AuthenticatedLink: FC<AuthenticatedLinkProps> = (props) => {
    const {
        url,
        children,
        className,
        token,
    } = props;

    const link = useRef<HTMLAnchorElement | null>(null);

    const handleAction = async () => {
        if(link && link.current) {
            let form = document.createElement('form')
            form.method = 'post'
            form.target = '_blank'
            form.action = url
            form.innerHTML = '<input type="hidden" name="authorization" value="Bearer ' + token + '">'
            //console.log('form:', form)
            document.body.appendChild(form)
            form.submit()
            document.body.removeChild(form)
        }
    }

    return (
        <>
            <a
                className={className}
                role='button'
                ref={link}
                onClick={handleAction}
            >
                {children}
            </a>
        </>
    )
}
