
export interface BreadcrumbsProps {
    instance?: any;
}


export default function Breadcrumbs({className, instance}: React.HTMLAttributes<HTMLElement> & BreadcrumbsProps) {
    return (
        <></>
    );    
}
