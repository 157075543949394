import { Card, CardContent } from "./card";
import { Input } from "./input";
import { cn } from "../../lib/utils";
import type { ChangeEvent} from "react";
import React, { useRef } from "react";

interface DropzoneProps
    extends Omit<
        React.InputHTMLAttributes<HTMLInputElement>,
        "value" | "onChange"
    > {
    classNameWrapper?: string;
    className?: string;
    dropMessage: string;
    handleOnDrop: (acceptedFiles: FileList | null) => void;
    renderChildren?: () => React.ReactNode;
}

const Dropzone = React.forwardRef<HTMLDivElement, DropzoneProps>(
    function Dropzone(
        { className, classNameWrapper, dropMessage, handleOnDrop, ...props },
        ref
    ) {
        const inputRef = useRef<HTMLInputElement | null>(null);
        // Function to handle drag over event
        const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
            e.preventDefault();
            e.stopPropagation();
            handleOnDrop(null);
        };

        // Function to handle drop event
        const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
            e.preventDefault();
            e.stopPropagation();
            const { files } = e.dataTransfer;
            if (inputRef.current) {
                inputRef.current.files = files;
                handleOnDrop(files);
            }
        };

        // Function to simulate a click on the file input element
        const handleButtonClick = () => {
            if (inputRef.current) {
                inputRef.current.click();
            }
        };
        console.log("dropzone props", props);
        return (
            <Card
                ref={ref}
                className={cn(
                    `border-2 border-dashed bg-muted hover:cursor-pointer hover:border-muted-foreground/50`,
                    classNameWrapper
                )}
            >
                <CardContent
                    className="flex flex-col items-center justify-center space-y-2 px-2 py-4 text-xs"
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    onClick={handleButtonClick}
                >
                    <div className="flex items-center justify-center text-muted-foreground">
                        <span className="font-medium">{dropMessage}</span>
                        <Input
                            value={undefined}
                            accept="image/jpg, image/gif, image/png, image/jpeg"
                            ref={inputRef}
                            type="file"                            
                            className={cn("hidden", className)}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                handleOnDrop(e.target.files)
                            }
                        />
                    </div>
                    {props.children}
                </CardContent>
            </Card>
        );
    }
);

export default Dropzone;