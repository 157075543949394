
export
const CLASSES = {
    dialog: {
        button: "w-[185px]",
    },
    submit: {
        button: "w-[160px]",
    },
    normal: {
        label: "w-[250px]",
        description: "w-[230px]",
        value: "w-[700px]",
        button: "w-[700px]",
        content: "",
    },
    inSection: {
        label: "w-[250px]",
        description: "w-[230px]",
        value: "min-w-[435px] w-[435px]",
        button: "min-w-[435px]",
        content: "",
    }
}
